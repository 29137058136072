import React, { Component } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

import ClientAPI from '../service/ClientAPI';
import Events from '../service/Events';

class ChooseProductPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      amount: 1
    };
  }

  handleShow = (title) => this.setState({ show: true, title });

  handleClose = () => {
    this.setState({ show: false });

    this.props.onClose();
  }

  handleSubmit = (ev) => {
    ClientAPI.SelectProduct(this.props.product.ID, this.state.amount)
      .then((_res) => {
        Events.onResult.emit("success", "Die Produkte wurden hinzugefügt!");

        this.handleClose();
      })
      .catch(console.error);

    ev.preventDefault();
  }

  componentDidUpdate() {
    if (this.state.show !== this.props.show) {
      this.setState({ show: this.props.show, amount: 1 });
    }
  }

  handleChangeAmount = (ev) => {
    var amount = ev.target.value;

    this.setState({ amount });
  }

  render() {
    return (
      this.props.product ?
        (
          <Modal
            show={this.state.show}
            onHide={this.handleClose}
            backdrop="static"
            keyboard={false}
          >
            <Modal.Header>
              <Modal.Title>Produkt Übersicht</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={this.handleSubmit}>
                <Form.Group className="mb-3" controlId="formProductName">
                  <Form.Label>Produktname</Form.Label>
                  <Form.Control type="text" name="product" value={this.props.product.Name} readOnly />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formAmount">
                  <Form.Label>Anzahl</Form.Label>
                  <Form.Control type="number" name="amount" placeholder="1" min="1" onChange={this.handleChangeAmount} />
                </Form.Group>

                {
                  this.props.product.isFixed ?
                    (
                      <Form.Group className="mb-3" id="formTotal">
                        <Form.Label>Gesamtpreis</Form.Label>
                        <Form.Control name="total" type="text" value={`${(this.state.amount * this.props.product.Price).toFixed(2)}€`} readOnly />
                      </Form.Group>
                    ) :
                    <></>
                }

                <Button variant="primary" type="submit">
                  Auswählen
                </Button>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleClose}>
                Schließen
              </Button>
            </Modal.Footer>
          </Modal>
        )
        :
        <></>
    );
  }
}

export default ChooseProductPopup;