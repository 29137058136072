import { Dayjs } from "dayjs";
import nodeFetch from "./fetch";

const FetchUserOrders = () => new Promise((resolve, reject) => {
    nodeFetch("userTable")
        .then(resolve)
        .catch(reject);
});

const CreateUser = (body) => new Promise((resolve, reject) => {
    nodeFetch("createUser", "post", body)
        .then(resolve)
        .catch(reject);
});

const UpdateProducts = (products) => new Promise((resolve, reject) => {
    nodeFetch("updateProducts", "post", products)
        .then(resolve)
        .catch(reject);
});

const CreateProduct = (data) => new Promise((resolve, reject) => {
    nodeFetch("createProduct", "post", data)
        .then(resolve)
        .catch(reject);
});

const DeleteProducts = (data) => new Promise((resolve, reject) => {
    nodeFetch("deleteProducts", "post", data)
        .then(resolve)
        .catch(reject);
});

const DeleteCustomerOrders = (data) => new Promise((resolve, reject) => {
    nodeFetch("deleteCustomerOrders", "post", data)
        .then(resolve)
        .catch(reject);
});

const EditCustomerOrders = (data) => new Promise((resolve, reject) => {
    nodeFetch("editCustomerOrders", "post", data)
        .then(resolve)
        .catch(reject);
});

const PromoteToAdmin = (customerID) => new Promise((resolve, reject) => {
    nodeFetch("promoteAdmin", "post", {
            customerID
        })
        .then(resolve)
        .catch(reject);
});

const DeleteCustomer = (customerID) => new Promise((resolve, reject) => {
    nodeFetch("deleteUser", "post", {
            customerID
        })
        .then(resolve)
        .catch(reject);
});

const DeletePendingUser = (userID) => new Promise((resolve, reject) => {
    nodeFetch("deletePendingUser", "post", {
            userID
        })
        .then(resolve)
        .catch(reject);
});

/**
 * @param {Dayjs} startDate 
 * @param {Dayjs} endDate 
 * @returns 
 */
const UpdateStoreOpeningTimes = (startDate, endDate) => new Promise((resolve, reject) => {
    nodeFetch("updateOpeningTimes", "post", {
            start: startDate.toJSON(),
            end: endDate.toJSON()
        })
        .then(resolve)
        .catch(reject);
});

const SetPriceWeightForCustomerOrder = (orderID, price, weight) => new Promise((resolve, reject) => {
    nodeFetch("setPriceForCustomerOrder", "post", {
            orderID,
            price,
            weight
        })
        .then(resolve)
        .catch(reject);
});

const RequestExcelSheet = () => new Promise((resolve, reject) => {
    nodeFetch("requestExcelSheet")
        .then(resolve)
        .catch(reject);
});

const ChangeTotalWeight = (weight) => new Promise((resolve, reject) => {
    nodeFetch("changeTotalWeight", "post", {
            weight
        })
        .then(resolve)
        .catch(reject);
});

const SwapOrderOfProducts = (products) => new Promise((resolve, reject) => {
    nodeFetch("swapOrderOfProductsSingle", "post", {
            productIDs: products
        })
        .then(resolve)
        .catch(reject);
});

/**
 * @param {{ isInfinite : boolean, amount : number, startDate : Date,  endDate : Date }} inviteOptions 
 * @returns 
 */
const CreateInvite = (inviteOptions) => new Promise((resolve, reject) => {
    nodeFetch("createInvite", "post", inviteOptions)
        .then(resolve)
        .catch(reject);
});

const DeleteAllOrders = () => new Promise((resolve, reject) => {
    nodeFetch("deleteAllOrders", "post", { temp: "temp" })
        .then(resolve)
        .catch(reject);
});

const PublishAllSelected = (productIDs) => new Promise((resolve, reject) => {
    nodeFetch("publishAll", "post", { products: productIDs })
        .then(resolve)
        .catch(reject);
});

const UnpublishAllSelected = (productIDs) => new Promise((resolve, reject) => {
    nodeFetch("unpublishAll", "post", { products: productIDs })
        .then(resolve)
        .catch(reject);
});

const exportData = {
    FetchUserOrders: FetchUserOrders,
    CreateUser,
    UpdateProducts,
    CreateProduct,
    DeleteProducts,
    DeleteCustomerOrders,
    EditCustomerOrders,
    PromoteToAdmin,
    DeletePendingUser,
    DeleteCustomer,
    UpdateStoreOpeningTimes,
    SetPriceWeightForCustomerOrder,
    RequestExcelSheet,
    ChangeTotalWeight,
    SwapOrderOfProducts,
    CreateInvite,
    DeleteAllOrders,
    PublishAllSelected,
    UnpublishAllSelected
};

export default exportData;