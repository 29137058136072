import * as React from 'react';

import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import { Delete, Edit, AdminPanelSettings, Info, ContentCopy, CopyAll } from '@mui/icons-material';
import { Checkbox, TablePagination, Tooltip } from '@mui/material';
import AdminAPI from '../service/AdminAPI';
import UpdateCustomerProductsPopup from './UpdateCustomerProductsPopup';

import Events from '../service/Events';
import SetCustomPriceWeightPopup from './SetCustomPriceWeightPopup';

class Row extends React.Component {
  constructor(props) {
    super(props);

    console.log(props.customer);

    this.state = {
      customer: props.customer,
      products: props.products,
      open: false,
      selected: [],
      show: false,
      showCustom: false,
      fixedProduct: null
    };
  }

  handleSelectAllClick = (event) => {
    if (this.state.selected == null || this.state.selected.length <= 0) {

      this.setState((state) => {
        state.selected = this.state.customer.orders.map(_order => _order);
        return state;
      });

      return;
    }

    this.setState({ selected: [] });
  };

  displayOrderData = (order, amount, type) => order.isFixed ? `${((type === "€" ? order.Price : order.Weight) * amount).toFixed(2)}${type}` : order.CustomPrice !== 0 ? `${type === "€" ? order.CustomPrice : order.CustomWeight}${type}` : "wird berechnet...";

  selectOrderChanged(ev, order) {
    if (ev.target.checked === true) {
      if (!order.isFixed) {
        this.setState({ fixedProduct: order });
      }

      this.setState({ selected: this.state.selected.concat([order]) })
    } else {
      var _ind = this.state.selected.findIndex(_order => _order.orderID === order.orderID);

      if (this.state.fixedProduct && this.state.fixedProduct.orderID === order.orderID) {
        this.setState({ fixedProduct: null });
      }

      if (_ind !== -1) {
        this.state.selected.splice(_ind, 1);
        this.setState({ selected: this.state.selected });
      }
    }
  }

  editCustomerOrders = (editedOrders) => {
    AdminAPI.EditCustomerOrders({
      customerID: this.state.customer.userID,
      orders: editedOrders
    })
      .then(() => {
        window.location.reload();
      })
      .catch(console.error);
  }

  deleteOrders = () => {
    AdminAPI.DeleteCustomerOrders({
      customerID: this.state.customer.userID,
      orders: this.state.selected.map(_order => _order.orderID)
    })
      .then(() => {
        window.location.reload();
      })
      .catch(console.error);
  }

  promoteToAdmin = () => {
    Events.onAlert.emit("warning", "Möchten Sie diesen Nutzer zu einem Administrator befördern?", "Der Nutzer kann dann alle Daten einsehen, sowie alle Aktionen ausführen, die Sie auch ausführen können!"
      , () => {
        AdminAPI.PromoteToAdmin(this.state.customer.userID)
          .then(() => window.location.reload())
          .catch(console.error);
      });
  }

  deleteUser = () => {
    Events.onAlert.emit("warning", "Möchten Sie diesen Nutzer wirklich löschen?", "Bei der Löschung werden alle Daten gelöscht (Bestellungen, Adresse, Account) und sind nicht wieder rückgänging zu machen!"
      , () => {
        AdminAPI.DeleteCustomer(this.state.customer.userID)
          .then(() => window.location.reload())
          .catch(console.error);
      });
  }

  deletePendingUser = () => {
    Events.onAlert.emit("warning", "Möchten Sie diesen Nutzer ausladen?", "Bei der Löschung wird der Einladungscode gelöscht, somit kann dieser Nutzer sich nicht mehr registrieren!"
      , () => {
        AdminAPI.DeletePendingUser(this.state.customer.userID)
          .then(() => window.location.reload())
          .catch(console.error);
      });
  }

  getTotalPriceAndWeightOfCustomer = () => {
    if (this.state.customer.isPending) {
      return (
        <>
          <TableCell>---</TableCell>
          <TableCell>---</TableCell>
        </>
      )
    }

    if (this.state.customer.orders.length <= 0) {
      return (
        <>
          <TableCell>0€</TableCell>
          <TableCell>0Kg/L</TableCell>
        </>
      )
    }

    const price = `${this.state.customer.orders.map(_order => _order.isFixed ? _order.Price * _order.Amount : _order.CustomPrice).reduce((a, b) => a + b).toFixed(2)}€`,
      weight = `${this.state.customer.orders.map(_order => _order.isFixed ? _order.Weight * _order.Amount : _order.CustomWeight).reduce((a, b) => a + b).toFixed(2)}Kg\\L`;

    return (
      <>
        <TableCell>{price}</TableCell>
        <TableCell>{weight}</TableCell>
      </>
    )
  }

  renderToolTipForRow = () => {
    return !this.state.customer.isPending ?
      (<IconButton title="Ansehen von Daten des Kunden"
        aria-label="expand row"
        size="small"
        onClick={() => this.setState({ open: !this.state.open })}
      >
        {this.state.open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </IconButton>
      )
      :
      (<IconButton title="Link kopieren"
        aria-label="copy token link"
        size="small"
        onClick={() => {
          var _websitePort = process.env.REACT_APP_WEBSITE_PORT;

          navigator.clipboard.writeText(`${process.env.REACT_APP_DOMAIN}${(_websitePort && (_websitePort !== "443" && _websitePort !== "80") ? `:${_websitePort}` : "")}/register?id=${this.state.customer.linkToken}`)
          Events.onResult.emit('success', "Der Link wurde kopiert");
        }}
      >
        <ContentCopy />
      </IconButton>
      )
  }

  renderNameColumn = () => {
    if (!this.state.customer.isPending) {
      return `${this.state.customer.firstName} ${this.state.customer.lastName}`
    } else {
      let tempToken = this.state.customer.linkToken;

      return `${tempToken.split('').splice(0, 10).join('')}...`;
    }
  }

  showStatus = () => {
    const { customer } = this.state;

    if (!customer.isPending && customer.email) {
      return customer.isAdmin ? "Administrator" : "Kunde";
    } else if (customer.isPending && customer.email) {
      return "Ausstehend...";
    } else {
      return (<div>
        <p>Nutzung: {customer.amountUsed}/{(customer.isInfinite ? "--" : customer.maxAmount)}</p>
        <p>Gültig: {this.state.customer.start.split('T')[0]} - {this.state.customer.end.split('T')[0]}</p>
      </div>
      )
    }
  }

  showOrderStatus = () => {
    if (this.state.customer.orders.length <= 0) {
      return "Keine Bestellungen vorhanden";
    }

    for (var i = 0; i < this.state.customer.orders.length; i++) {
      if (!this.state.customer.orders[i].Confirmed) {
        return "Bestellungen werden noch bearbeitet"
      }
    }

    return "Bestellungen bestätigt"
  }

  render() {
    return (
      <React.Fragment>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
          <TableCell>
            {
              this.renderToolTipForRow()
            }
          </TableCell>
          <TableCell component="th" scope="row">
            {
              this.renderNameColumn()
            }
          </TableCell>
          <TableCell>{(this.state.customer.email ? this.state.customer.email : "Einladungslink")}</TableCell>
          {
            this.getTotalPriceAndWeightOfCustomer()
          }
          <TableCell>
            {
              this.showStatus()
            }
          </TableCell>
          <TableCell>
            {
              this.showOrderStatus()
            }
          </TableCell>
          <TableCell align="right">
            <Tooltip title='Entfernen des Nutzers'>
              <IconButton onClick={() => this.state.customer.isPending ? this.deletePendingUser() : this.deleteUser()}>
                <Delete />
              </IconButton>
            </Tooltip>
            {!this.state.customer.isPending && !this.state.customer.isAdmin ? (
              <>
                <IconButton title='Nutzer zum Administrator befördern' onClick={this.promoteToAdmin}>
                  <AdminPanelSettings />
                </IconButton>
              </>
            )
              :
              <></>
            }
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={this.state.open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  Alle ausgewählten Produkte des Kunden
                </Typography>
                {
                  this.state.selected.length > 0 ?
                    (
                      <div>
                        <IconButton title="Entfernen des Produktes vom Kunden" onClick={() => {
                          Events.onAlert.emit("warning", "Möchten Sie alle ausgewählten Produkte vom Kunden löschen?", "Wenn Sie alle ausgewählten Produkte löschen, besitzt dieser Kunde diese Produkte nicht mehr!"
                            , () => {
                              this.deleteOrders();
                            })
                        }}>
                          <Delete />
                        </IconButton>
                        <IconButton title='Anzahl ändern oder Bestellung bestätigen' onClick={() => this.setState({ show: true })}>
                          <Edit />
                        </IconButton>
                        {
                          this.state.selected.length === 1 && this.state.fixedProduct ?
                            (
                              <Tooltip title="Bearbeiten von Einzelprodukt - Details, Preis und Gewicht" onClick={() => this.setState({ showCustom: true })}>
                                <IconButton>
                                  <Info />
                                </IconButton>
                              </Tooltip>
                            ) :
                            <></>
                        }
                      </div>
                    )
                    :
                    <></>
                }
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          indeterminate={this.state.selected.length > 0 && this.state.selected.length === this.state.customer.orders.map(_order => _order).length}
                          checked={this.state.selected.length > 0 && this.state.selected.length === this.state.customer.orders.map(_order => _order).length}
                          onChange={this.handleSelectAllClick}
                          inputProps={{
                            'aria-label': 'select all products',
                          }}
                        />
                      </TableCell>
                      <TableCell>Produktname</TableCell>
                      <TableCell>Preis</TableCell>
                      <TableCell align="right">Anzahl</TableCell>
                      <TableCell align="right">Gesamtwert (€)</TableCell>
                      <TableCell align="right">Gesamtgewicht (Kg\L)</TableCell>
                      <TableCell align='right'>Bestellstatus</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.customer.orders.length ? this.state.customer.orders.map((_order) => (
                      <TableRow key={_order.orderID}>
                        <TableCell>
                          <Checkbox checked={this.state.selected.find(_selected => _selected.orderID === _order.orderID) != null} onChange={(ev) => this.selectOrderChanged(ev, _order)} />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          {_order.Name}
                        </TableCell>
                        <TableCell>{this.displayOrderData(_order, 1, "€")}</TableCell>
                        <TableCell align="right">{_order.Amount}x</TableCell>
                        <TableCell align="right">{this.displayOrderData(_order, _order.isFixed ? _order.Amount : 1, "€")}</TableCell>
                        <TableCell align="right">{this.displayOrderData(_order, _order.isFixed ? _order.Amount : 1, "Kg\\L")}</TableCell>
                        <TableCell align='right'>{_order.Confirmed ? "Bestellung bestätigt" : "Bestellung wird bearbeitet..."}
                        </TableCell>
                      </TableRow>
                    ))
                      :
                      <TableRow>
                        <TableCell>Dieser Benutzer besitzt noch keine Produkte</TableCell>
                      </TableRow>
                    }
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
        <UpdateCustomerProductsPopup show={this.state.show} customSubmit={(edited) => this.editCustomerOrders(edited)} orders={this.state.selected} onClose={() => this.setState({ show: false })} />
        <SetCustomPriceWeightPopup show={this.state.showCustom} order={this.state.fixedProduct} onClose={() => this.setState({ showCustom: false })} />
      </React.Fragment>
    );
  }
}

export default class CustomerProductsTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      customers: props.customers,
      products: props.products,
      page: 0,
      rowsPerPage: 5
    };
  }

  getTotalPriceAndWeight = () => {
    if (this.props.allOrders.length <= 0) {
      return {
        price: 0,
        weight: 0
      }
    }

    let price = 0, weight = 0;

    for (var i = 0; i < this.props.allOrders.length; i++) {
      var _userOrders = this.props.allOrders[i];

      for (var j = 0; j < _userOrders.orders.length; j++) {
        var _order = _userOrders.orders[j];

        price += _order.isFixed ? _order.Price * _order.Amount : _order.CustomPrice;
        weight += _order.isFixed ? _order.Weight * _order.Amount : _order.CustomWeight;
      }
    }

    return {
      price,
      weight
    };
  }

  componentDidUpdate() {
    if (this.state.customers !== this.props.customers || this.state.products !== this.props.products) {
      this.setState({ page: 0, customers: this.props.customers, products: this.props.products });
    }
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ page: 0, rowsPerPage: parseInt(event.target.value, 10) })
  };

  saveEmailsToClipboard = () => {
    const emails = this.state.customers.filter(customer => !customer.isPending)
      .map(customer => customer.email)
      .join(', ');

    navigator.clipboard.writeText(emails);

    Events.onResult.emit("success", "Die Emails wurden kopiert.");
  }

  render() {
    return (
      <>
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell width={"25px"} padding='normal' />
                <TableCell>Name / Einladungslink</TableCell>
                <TableCell>
                  <span>Email</span>
                  <Tooltip title="Kopieren von allen Emails" onClick={this.saveEmailsToClipboard}>
                    <IconButton>
                      <CopyAll />
                    </IconButton>
                  </Tooltip>
                </TableCell>
                <TableCell>Gesamtpreis (Alle: {this.getTotalPriceAndWeight().price.toFixed(2)}€)</TableCell>
                <TableCell>Gesamtgewicht (Alle: {this.getTotalPriceAndWeight().weight.toFixed(2)} Kg\L)</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Bestellstatus</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.customers.filter((customer, index) => index >= (this.state.page * this.state.rowsPerPage) && index < ((this.state.page + 1) * this.state.rowsPerPage)).map((customer) => (
                <Row key={customer.userID + (customer.isPending ? this.state.customers.length : 0)} customer={customer} products={this.state.products} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <TablePagination
            labelRowsPerPage="Zeilen pro Spalte:"
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={this.state.customers.length}
            rowsPerPage={this.state.rowsPerPage}
            page={this.state.page}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
          />
        </Paper>
      </>
    );
  }
}