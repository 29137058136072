import React, { Component } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

import AdminAPI from '../service/AdminAPI';

class UpdateProductsPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      cachedProducts: null,
      products: []
    }
  }

  handleShow = (title) => this.setState({ show: true, title });

  handleClose = () => {
    this.setState({ show: false });

    this.props.onClose();
  }

  handleSubmit = (ev) => {
    AdminAPI.UpdateProducts({ products: this.state.cachedProducts })
      .then((_res) => {
        window.location.reload();
      })
      .catch(console.error);

    ev.preventDefault();
  }

  componentDidUpdate() {
    if (this.state.show !== this.props.show) {
      this.setState({ show: this.props.show, products: this.props.products, cachedProducts: this.props.products.map(p => { return { ...p } }) });
    }
  }

  handleNameChange = (ev, productID) => {
    var _ind = this.state.products.findIndex(_product => _product.ID === productID);

    if (_ind === -1) {
      return;
    }

    this.state.cachedProducts[_ind].Name = ev.target.value;

    this.setState({ products: this.state.products });
  }

  handleSortIDChange = (ev, productID) => {
    var _ind = this.state.products.findIndex(_product => _product.ID === productID);

    if (_ind === -1) {
      return;
    }

    this.state.cachedProducts[_ind].SortID = parseInt(ev.target.value);

    this.setState({ products: this.state.products });
  }

  handlePriceChange = (val, productID) => {
    var _ind = this.state.products.findIndex(_product => _product.ID === productID);

    if (_ind === -1) {
      return;
    }

    this.state.cachedProducts[_ind].Price = val;

    this.setState({ products: this.state.products });
  }

  handleWeightChange = (val, productID) => {
    var _ind = this.state.products.findIndex(_product => _product.ID === productID);

    if (_ind === -1) {
      return;
    }

    this.state.cachedProducts[_ind].Weight = val;

    this.setState({ products: this.state.products });
  }

  updateFixedValues = (hasFixedValues, productID) => {
    var _ind = this.state.products.findIndex(_product => _product.ID === productID);

    if (_ind === -1) {
      return;
    }

    this.state.cachedProducts[_ind].Price = hasFixedValues ? 1 : 0;
    this.state.cachedProducts[_ind].Weight = hasFixedValues ? 1 : 0;
    this.state.cachedProducts[_ind].isFixed = hasFixedValues;

    this.setState({ products: this.state.products });
  }

  resetEntry = (productID) => {
    var _ind = this.state.products.findIndex(_product => _product.ID === productID);

    if (_ind === -1) {
      return;
    }

    this.state.cachedProducts[_ind].Name = this.state.products[_ind].Name;
    this.state.cachedProducts[_ind].Price = this.state.products[_ind].Price;
    this.state.cachedProducts[_ind].Weight = this.state.products[_ind].Weight;
    this.state.cachedProducts[_ind].isFixed = this.state.products[_ind].isFixed;
    this.state.cachedProducts[_ind].SortID = this.state.products[_ind].SortID;

    this.setState({ cachedProducts: this.state.cachedProducts });
  }

  render() {
    return (
      this.state.cachedProducts ?
        (
          <Modal
            show={this.state.show}
            onHide={this.handleClose}
          >
            <Modal.Header>
              <Modal.Title>Produkt Übersicht</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={this.handleSubmit}>
                {
                  this.state.cachedProducts.map(_product => {
                    return (
                      <div key={_product.ID}>
                        <Form.Group className="mb-3" controlId="formProductName">
                          <Form.Label>Produktname</Form.Label>
                          <Form.Control type="text" name={_product.Name} value={_product.Name} onChange={(ev) => this.handleNameChange(ev, _product.ID)} />
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formWeight">
                          <Form.Label>Hat dieses Produkt ein fixiertes Gewicht und somit einen fixierten Preis?</Form.Label>
                          <Form.Check value={_product.isFixed} checked={_product.isFixed} onChange={() => this.updateFixedValues(!_product.isFixed, _product.ID)}></Form.Check>
                        </Form.Group>

                        {
                          _product.isFixed ?
                            <>
                              <Form.Group className="mb-3" controlId="formWeight">
                                <Form.Label>Gewicht</Form.Label>
                                <Form.Control type="number" value={_product.Weight} min="0" step="0.01" name="weight" placeholder="Gewicht eingeben..." onChange={(ev) => this.handleWeightChange(ev.target.value, _product.ID)} />
                              </Form.Group>

                              <Form.Group className="mb-3" controlId="formPrice">
                                <Form.Label>Preis</Form.Label>
                                <Form.Control step="0.01" type="number" name="Price" value={_product.Price} min="0" placeholder="Preis eingeben..." onChange={(ev) => this.handlePriceChange(ev.target.value, _product.ID)} />
                              </Form.Group>
                            </>
                            :
                            <></>
                        }

                        <Button onClick={() => this.resetEntry(_product.ID)} >
                          Zurücksetzen
                        </Button>
                        <br />
                        <hr />
                      </div>
                    )
                  })
                }

                <Button variant="primary" type="submit">
                  Produkt aktualisieren
                </Button>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleClose}>
                Schließen
              </Button>
            </Modal.Footer>
          </Modal>
        )
        :
        <></>
    );
  }
}

export default UpdateProductsPopup;