const Save = (key, value) => {
    localStorage.setItem(key, value);
}

const SaveMultiple = (keyValuePairs) => {
    for(var i = 0; i < keyValuePairs.length; i++) {
        Save(keyValuePairs[i].key, keyValuePairs[i].value);
    }
}

const DeleteMultiple = (keys) => {
    for(var i = 0; i < keys.length; i++) {
        Delete(keys[i]);
    }
}

const Get = (key) => {
    return localStorage.getItem(key);
}

const Delete = (key) => {
    localStorage.removeItem(key);
}

const exportData = { Save, Get, Delete, SaveMultiple, DeleteMultiple };

export default exportData;