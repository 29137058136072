import React, { Component } from "react";
import { Form, Row, Button, Col } from 'react-bootstrap';

import CookieManager from "../managers/CookieManager";
import LocalStorageManager from "../managers/LocalStorageManager";
import Events from '../service/Events';

import LocationReader from "../managers/LocationReader";
import ClientAPI from "../service/ClientAPI";
import PageDescription from "../components/PageDescription";

class Register extends Component {
    constructor(props) {
        super(props);

        this.state = {
            token: LocationReader.ReadParameter("id", props.location.search)
        }
    }

    onSubmit = (ev) => {
        ev.preventDefault();

        const _inputs = ev.target,
            _email = _inputs.email.value,
            _password = _inputs.password.value,
            _rpassword = _inputs.rpassword.value,
            _save = _inputs.save.checked,
            _firstName = _inputs.firstName.value,
            _lastName = _inputs.lastName.value;

        if (!_password || !_rpassword || _password !== _rpassword) {
            Events.onResult.emit("error", "Passwörter stimmen nicht überein!");
            return;
        }

        var _websitePort = process.env.REACT_APP_WEBSITE_PORT;
        const _homepage = `${process.env.REACT_APP_DOMAIN}${(_websitePort ? `:${_websitePort}` : "")}`;

        ClientAPI.Register({
            token: this.state.token, _email, _password, _rpassword, _firstName, _lastName
        })
            .then((_res) => {
                CookieManager.Save("auth", _res.token);
                LocalStorageManager.Save("admin", _res.isAdmin);

                if (_save) {
                    LocalStorageManager.SaveMultiple([
                        { key: "isSaved", value: _save },
                        { key: "password", value: _password },
                        { key: "email", value: _email }
                    ]);
                }

                window.location.replace(_homepage);
            })
            .catch(console.error);
    }

    render() {
        return (
            <div>
                <h3 style={{ textAlign: "center", marginTop: "30px", marginBottom: "15px" }}>Registrierung</h3>
                <div style={{ width: "80%", margin: "auto" }}>
                    <hr />
                    <PageDescription greetings={true} />
                    <hr />
                    <div style={{ minHeight: "90vh", margin: "auto", marginTop: "25px" }}>
                        <Form className="registerForm" onSubmit={this.onSubmit}>
                            <Form.Group style={{ gridArea: "email" }} controlId="formGridEmail">
                                <Form.Label>E-Mail</Form.Label>
                                <Form.Control name="email" type="email" placeholder="E-Mail" />
                            </Form.Group>

                            <Form.Group style={{ gridArea: "firstName" }} as={Col} controlId="formGridFirstname">
                                <Form.Label>Vorname</Form.Label>
                                <Form.Control name="firstName" type="text" placeholder="Vorname" />
                            </Form.Group>

                            <Form.Group style={{ gridArea: "lastName" }} as={Col} controlId="formGridLastName">
                                <Form.Label>Nachname</Form.Label>
                                <Form.Control name="lastName" type="text" placeholder="Nachname" />
                            </Form.Group>

                            <Form.Group style={{ gridArea: "password" }} as={Col} controlId="formGridPassword">
                                <Form.Label>Passwort</Form.Label>
                                <Form.Control name="password" type="password" placeholder="Passwort" />
                            </Form.Group>

                            <Form.Group style={{ gridArea: "repeatPassword" }} as={Col} controlId="formGridRepeatPassword">
                                <Form.Label>Passwort wiederholen</Form.Label>
                                <Form.Control name="rpassword" type="password" placeholder="Passwort wiederholen" />
                            </Form.Group>

                            <Form.Group style={{ gridArea: "save" }} className="mb-3" id="formGridCheckbox">
                                <Form.Check name="save" type="checkbox" label="Anmeldedaten speichern" />
                            </Form.Group>

                            <Button style={{ width: "200px", margin: "auto", gridArea: "submit" }} variant="primary" type="submit">
                                Registrieren
                            </Button>
                        </Form>
                    </div>
                </div>
            </div>
        )
    }
}

export default Register;