import React, { Component } from "react";
import PageDescription from "../components/PageDescription";

class InfoToPricingPage extends Component {
    render() {
        return (
            <div>
                <h3 style={{ textAlign: "center", marginTop: "30px", marginBottom: "15px" }}>Hinweis zu den Preisen und Bestellungen</h3>
                <div style={{ width: "80%", margin: "auto", minHeight: "85vh" }}>
                    <hr />
                    <PageDescription />
                    <hr />
                </div>
            </div>
        )
    }
}

export default InfoToPricingPage;