import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Events from '../service/Events';

class WarningPopup extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            text: null,
            title: null,
            onAgree: () => { }
        };
    }

    componentDidMount() {
        Events.onAlert.on("warning", (title, text, onAgree) => {
            this.setState({ open: true, title, text, onAgree });
        });
    }

    handleClose = () => {
        this.setState({ open: false });
    };

    render() {
        return (
            <div>
                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {this.state.title}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {this.state.text}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleClose}>Abbrechen</Button>
                        <Button onClick={(ev) => {
                            this.handleClose();
                            this.state.onAgree();
                        }} autoFocus>
                            Bestätigen
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}

export default WarningPopup;