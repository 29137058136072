const Save = (key, value) => {
    var _cookies = document.cookie.split(';');

    for(var i = 0; i < _cookies.length; i++) {
        var _cookie = _cookies[i].split('=');

        if(_cookie[0] === key) {
            _cookie[1] = value;
            _cookies[i] = _cookie[0] + "=" + _cookie[1];

            document.cookie = _cookies.join(';');
            return;
        }
    }

    document.cookie += `${key}=${value}`;
}

const Get = (key) => {
    var _cookies = document.cookie.split(';');

    for(var i = 0; i < _cookies.length; i++) {
        var _cookie = _cookies[i].split('=');

        if(_cookie[0] === key) {
            return _cookie[1];
        }
    }

    return null;
}

const Delete = (key) => {
    var _cookies = document.cookie.split(';');

    for(var i = 0; i < _cookies.length; i++) {
        var _cookie = _cookies[i].split('=');

        if(_cookie[0] === key) {
            _cookies[i] = key + '=; expires=Thu, 01 Jan 1970 00:00:01 GMT;'

            document.cookie = _cookies.join(';');

            return true;
        }
    }

    return false;
}

const exportData = { Save, Get, Delete };

export default exportData;