import * as React from 'react';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import UpdateProductsPopup from './UpdateProductsPopup';
import { Edit, Publish, SwapVert, UnpublishedTwoTone } from '@mui/icons-material';

import Events from '../service/Events';
import AdminAPI from '../service/AdminAPI';

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Name des Produkts',
  },
  {
    id: "published",
    numeric: false,
    disablePadding: true,
    label: "Status"
  },
  {
    id: 'price',
    numeric: true,
    disablePadding: false,
    label: 'Preis',
  },
  {
    id: 'weight',
    numeric: true,
    disablePadding: false,
    label: 'Gewicht',
  }
];

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount } = props;

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all products',
            }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const EnhancedTableToolbar = (props) => {
  const { numSelected, onEdit, onDeleteProducts, onPublish, onUnpublish, onSwap } = props;

  return (
    <>
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: '1 1 100%' }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            sx={{ flex: '1 1 100%' }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Produkte
          </Typography>
        )}

        {numSelected > 0 ? (
          <>
            {
              numSelected === 2 ? (
                <Tooltip onClick={onSwap} title='Tauschen der Sortierung von zwei selektierten Produkten' >
                  <IconButton>
                    <SwapVert />
                  </IconButton>
                </Tooltip>
              ) : (<></>)
            }
            <Tooltip title="Veröffentlichen von ausgewählten Produkten" onClick={() =>
              Events.onAlert.emit("warning", "Möchten Sie alle ausgewählten Produkte veröffentlichen?", "Somit werden diese Produkte wieder auf der Startseite angezeigt und können ausgewählt werden."
                , () => {
                  onPublish();
                })
            }>
              <IconButton>
                <Publish />
              </IconButton>
            </Tooltip>
            <Tooltip title="Verbergen von ausgewählten Produkten" onClick={() =>
              Events.onAlert.emit("warning", "Möchten sie alle ausgewählten Produkte verbergen?", "Somit werden diese Produkte nicht mehr auf der Produktseite angezeigt und in der Tabelle zum runterladen ignoriert (Alle Bestellungen mit diesen Produkten werden auch automatisch gelöscht)."
                , () => {
                  onUnpublish();
                })
            }>
              <IconButton>
                <UnpublishedTwoTone />
              </IconButton>
            </Tooltip>
            <Tooltip title="Löschen" onClick={() =>
              Events.onAlert.emit("warning", "Möchten Sie alle ausgewählten Produkte löschen?", "Wenn Sie alle ausgewählten Produkte löschen, werden auch alle Bestellungen für dieses Produkt gelöscht"
                , () => {
                  onDeleteProducts();
                })
            }>
              <IconButton>
                <DeleteIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Bearbeiten" onClick={() => onEdit()}>
              <IconButton>
                <Edit />
              </IconButton>
            </Tooltip>
          </>
        ) : (
          <>
          </>
        )}
      </Toolbar>
    </>
  );
};

export default class AllProductsTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      order: 'asc',
      orderBy: 'price',
      selected: [],
      page: 0,
      dense: false,
      rowsPerPage: 5,
      products: props.products,
      show: false
    };
  }

  componentDidUpdate() {
    if (this.state.products !== this.props.products) {
      this.setState({ page: 0, products: this.props.products })
    }
  }

  handleRequestSort = (event, property) => {
    const isAsc = this.state.orderBy === property && this.state.order === 'asc';
    this.setState({ order: isAsc ? 'desc' : 'asc', orderBy: property });
  };

  handleSelectAllClick = (event) => {
    if (event.target.checked) {
      this.setState({ selected: this.state.products });
      return;
    }

    this.setState({ selected: [] });
  };

  handleClick = (event, id) => {
    const selectedIndex = this.state.products.findIndex(p => p.ID === id);

    if (selectedIndex === -1) {
      return;
    }

    const copiedArr = Array.from(this.state.selected);

    if (copiedArr.length <= 0) {
      copiedArr.push(this.state.products[selectedIndex]);

      this.setState({ selected: copiedArr });
      return;
    }

    const ind = copiedArr.findIndex(p => p.ID === id);

    if (ind !== -1) {
      copiedArr.splice(ind, 1)
    } else {
      copiedArr.push(this.state.products[selectedIndex]);
    }

    this.setState({ selected: copiedArr });
  };

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: parseInt(event.target.value, 10), page: 0 });
  };

  handleChangeDense = (event) => {
    this.setState({ dense: event.target.checked });
  };

  isSelected = (id) => this.state.selected.findIndex(s => s.ID === id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  emptyRows =
    this.state ? this.state.page > 0 ? Math.max(0, (1 + this.state.page) * this.state.rowsPerPage - this.state.products.length) : 0 : 5;

  deleteProducts = () => {
    AdminAPI.DeleteProducts({ productIDs: this.state.selected.map(_prod => _prod.ID) })
      .then((_res) => {
        window.location.reload();
      })
      .catch(console.error);
  }

  swapProducts = () => {
    AdminAPI.SwapOrderOfProducts(this.state.selected.map(product => product.SortID))
      .then(() => window.location.reload())
      .catch(console.error);
  }

  publishAll = () => {
    AdminAPI.PublishAllSelected(this.state.selected.map(_prod => _prod.ID))
      .then((_res) => {
        window.location.reload();
      })
      .catch(console.error);
  }

  unpublishAll = () => {
    AdminAPI.UnpublishAllSelected(this.state.selected.map(_prod => _prod.ID))
      .then((_res) => {
        window.location.reload();
      })
      .catch(console.error);
  }

  render() {
    return (
      <>
        <Box sx={{ width: '100%' }}>
          <Paper sx={{ width: '100%', mb: 2 }}>
            <EnhancedTableToolbar
              onSwap={this.swapProducts}
              numSelected={this.state.selected.length}
              onEdit={() => this.setState({ show: true })}
              onDeleteProducts={this.deleteProducts}
              onPublish={this.publishAll}
              onUnpublish={this.unpublishAll}
            />
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={this.state.dense ? 'small' : 'medium'}
              >
                <EnhancedTableHead
                  numSelected={this.state.selected.length}
                  order={this.state.order}
                  orderBy={this.state.orderBy}
                  onSelectAllClick={this.handleSelectAllClick}
                  onRequestSort={this.handleRequestSort}
                  rowCount={this.state.products.length}
                />
                <TableBody>
                  {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                          rows.slice().sort(getComparator(order, orderBy)) */}
                  {this.state && this.state.products
                    .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                    .map((product, index) => {
                      const isItemSelected = this.isSelected(product.ID);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          onClick={(event) => this.handleClick(event, product.ID)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={product.ID}
                          selected={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                              color="primary"
                              checked={isItemSelected}
                              inputProps={{
                                'aria-labelledby': labelId,
                              }}
                            />
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            {product.Name}
                          </TableCell>
                          <TableCell align='left'>{product.Published ? "Veröffentlicht" : "Nicht veröffentlicht"}</TableCell>
                          <TableCell align="right">{product.isFixed ? product.Price + "€" : "Individuell"}</TableCell>
                          <TableCell align="right">{product.isFixed ? product.Weight + " Kg\\L" : "Individuell"}</TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              labelRowsPerPage="Zeilen pro Spalte:"
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={this.state.products.length}
              rowsPerPage={this.state.rowsPerPage}
              page={this.state.page}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
        <UpdateProductsPopup maxProducts={this.props.maxProducts} show={this.state.show} products={this.state.selected} onClose={() => this.setState({ show: false })} />
      </>
    );
  }
}