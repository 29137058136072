import React, { Component } from 'react';
import { Modal, Button, Form, Row } from 'react-bootstrap';

import ClientAPI from '../service/ClientAPI';
import Events from '../service/Events';

class UpdateCustomerProductsPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      cachedOrders: null,
      orders: []
    }
  }

  handleClose = () => {
    this.setState({ show: false });

    this.props.onClose();
  }

  handleSubmit = (ev) => {
    if (this.props.customSubmit) {
      this.props.customSubmit(this.state.cachedOrders);
    }
    else {
      console.log(this.state.cachedOrders);
      ClientAPI.UpdateMyOrders({ orders: this.state.cachedOrders })
        .then((_res) => {
          window.location.reload();
        })
        .catch(console.error);
    }

    ev.preventDefault();
  }

  componentDidUpdate() {
    if (this.state.show !== this.props.show) {
      this.setState({ show: this.props.show, orders: this.props.orders, cachedOrders: this.props.orders.map(o => { return { ...o } }) });
    }
  }

  handleAmountChange = (ev, orderID) => {
    var _ind = this.state.orders.findIndex(_order => _order.orderID === orderID);

    if (_ind === -1) {
      return;
    }

    this.state.cachedOrders[_ind].Amount = parseInt(ev.target.value);

    this.setState({ cachedOrders: this.state.cachedOrders });
  }

  resetEntry = (orderID) => {
    var _ind = this.state.orders.findIndex(_order => _order.orderID === orderID);

    if (_ind === -1) {
      return;
    }

    this.state.cachedOrders[_ind].Amount = this.state.orders[_ind].Amount;
    this.state.cachedOrders[_ind].SortID = this.state.orders[_ind].SortID;

    this.setState({ cachedOrders: this.state.cachedOrders });
  }

  cofirmOrder = (orderID) => {
    ClientAPI.ConfirmOrder(orderID)
      .then(() => window.location.reload())
      .catch(console.error);
  }

  render() {
    return (
      this.state.cachedOrders ?
        (
          <Modal
            show={this.state.show}
            onHide={this.handleClose}
          >
            <Modal.Header>
              <Modal.Title>Produkt Übersicht</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={this.handleSubmit}>
                {
                  this.state.cachedOrders.map(_order => {
                    return (
                      <div key={_order.orderID}>
                        <Form.Group className="mb-3" controlId="formProductName">
                          <Form.Label>Produktname</Form.Label>
                          <Form.Control type="text" name={_order.Name} value={_order.Name} readOnly />
                        </Form.Group>

                        {
                          _order.isFixed ?
                            (
                              <Form.Group className="mb-3" controlId="formPrice">
                                <Form.Label>Preis</Form.Label>
                                <Form.Control type="text" name="Price" value={`${_order.isFixed ? _order.Price + "€" : _order.CustomPrice !== 0 ? _order.CustomPrice + "€" : "Betrag noch nicht berechnet..."}`} readOnly />
                              </Form.Group>
                            )
                            :
                            <></>
                        }

                        <Form.Group className="mb-3" controlId="formAmount">
                          <Form.Label>Anzahl</Form.Label>
                          <Form.Control type="number" name="Price" value={_order.Amount} min="1" onChange={(ev) => this.handleAmountChange(ev, _order.orderID)} />
                        </Form.Group>

                        {
                          _order.isFixed ?
                            (
                              <Form.Group className="mb-3" controlId="formTotal">
                                <Form.Label>Gesamtpreis</Form.Label>
                                <Form.Control type="text" name="Price" value={`${(_order.Price * (_order.Amount ? _order.Amount : 0)).toFixed(2)}€`} readOnly />
                              </Form.Group>
                            )
                            :
                            <></>
                        }

                        <Row>
                          <Button style={{ maxWidth: "75%", margin: "auto", marginBottom: "10px" }} onClick={() => this.resetEntry(_order.orderID)} >
                            Zurücksetzen
                          </Button>
                          {/* <Button style={{ maxWidth: "75%", margin: "auto" }} variant="primary" onClick={() => Events.onAlert.emit("warning", "Möchten Sie diese Bestellung bestätigen?", "Wenn Sie die Bestellung bestätigen, können Sie die Bestellung nicht mehr bearbeiten und auch keine neuen Produkte, von dieser Art, hinzufügen!"
                            , () => {
                              this.cofirmOrder(_order.orderID);
                            })}>
                            Bestellung bestätigen
                          </Button> */}
                        </Row>
                        <br />
                        <hr />
                      </div>
                    )
                  })
                }
                <Button variant="primary" type="submit">
                  Bestellungen aktualisieren
                </Button>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleClose}>
                Schließen
              </Button>
            </Modal.Footer>
          </Modal>
        )
        :
        <></>
    );
  }
}

export default UpdateCustomerProductsPopup;