import React, { Component } from "react";
import { Form, FormControl, Button } from 'react-bootstrap';

class Searchbar extends Component {
    searchFor = (ev) => {
        if (this.props.searchRequest) {
            this.props.searchRequest(ev.target.value);
        }

        ev.preventDefault();
    }

    render() {
        return (
            <Form style={{  marginBottom: "15px" }} className="d-flex" onChange={this.searchFor} onSubmit={this.searchFor}>
                <FormControl
                    type="search"
                    placeholder={this.props.label ? this.props.label : "Search"}
                    className="me-2"
                    aria-label="Search"
                    name="search"
                />
                {/* <Button variant="outline-success" type="submit">Suchen</Button> */}
                {
                    this.props.addButton ? <Button style={{ marginLeft: "10px" }} onClick={() => this.props.onAdd()} variant="success">+</Button> : null
                }
                {
                    this.props.customChilds ? this.props.customChilds() : null
                }
                {
                    this.props.customButton ? <Button style={{ marginLeft: "10px" }} onClick={() => this.props.onCustomButton()} variant="success" disabled={this.props.loading}>{this.props.loading ? "Lädt..." : this.props.customButton.name}</Button> : null
                }
            </Form>
        );
    }
}

export default Searchbar;