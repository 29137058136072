import React, { Component } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

import AdminAPI from '../service/AdminAPI';
import { TextField } from '@mui/material';

class UpdateTotalWeight extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false
        }
    }

    handleShow = (title) => this.setState({ show: true, title });

    handleClose = () => {
        this.setState({ show: false });

        this.props.onClose();
    }

    handleSubmit = (ev) => {
        AdminAPI.ChangeTotalWeight(parseInt(ev.target.weight.value))
            .then(() => window.location.reload())
            .catch(console.error);

        ev.preventDefault();
    }

    componentDidUpdate() {
        if (this.state.show !== this.props.show) {
            this.setState({ show: this.props.show });
        }
    }

    render() {
        return (
            <Modal
                show={this.state.show}
                onHide={this.handleClose}
            >
                <Modal.Header>
                    <Modal.Title>Bearbeiten des Gesamtgewichts</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={this.handleSubmit} style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                        <TextField name="weight" type="number" placeholder={`${this.props.maxWeight}Kg/L`} />

                        <br />

                        <Button variant="primary" type="submit">
                            Gesamtgewicht aktualisieren
                        </Button>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.handleClose}>
                        Schließen
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default UpdateTotalWeight;