import CookieManager from '../managers/CookieManager';

import Events from './Events';

const Fetch = (urlPath, requestType = "get", body = null) => new Promise((resolve, reject) => {
    var _backendPort = process.env.REACT_APP_BACKEND_PORT;

    fetch(`${process.env.REACT_APP_DOMAIN}${(_backendPort ? `:${_backendPort}` : "")}/api/${urlPath}`, {
            headers: {
                "authorization": CookieManager.Get("auth"),
                "Content-Type": "application/json"
            },
            body: requestType === "get" || body === null ? null : JSON.stringify(body),
            method: requestType
        })
        .then(_data => {
            if (_data.headers.get("Content-Type") === "application/json; charset=utf-8") {
                return _data.json();
            }

            if (_data.headers.get("Content-Type") === "application/vnd.oasis.opendocument.spreadsheet") {
                return _data.blob();
            }
        })
        .then(_data => {
            if (_data.Error) {
                if (_data.ErrorCode === 0) {
                    Events.onResult.emit("onAuthTokenInvalid");
                } else {
                    Events.onResult.emit("error", _data.Error);
                }

                return reject(_data.Error);
            }

            resolve(_data.Success ? _data.Success : _data);
        })
        .catch((e) => {
            reject(e);
        });
});

export default Fetch;