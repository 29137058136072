import React, { Component } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

import ClientAPI from '../service/ClientAPI';
import Events from '../service/Events';
import CookieManager from '../managers/CookieManager';
import LocalStorageManager from '../managers/LocalStorageManager';

class ReLoginPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      title: "",
      email: null,
      password: null,
      resetPassword: false,
      errorMessage: null
    }
  }

  handleShow = (title) => this.setState({ show: true, title });

  handleClose = () => this.setState({ show: false, errorMessage: null });

  handleChange = (ev) => {
    var name = ev.target.name;

    this.setState({ [name]: ev.target.value });
  }

  handleLogin = (ev) => {
    this.setState({ errorMessage: null });

    ClientAPI.Login({
      password: this.state.password,
      email: this.state.email
    })
      .then((_res) => {
        CookieManager.Save("auth", _res.token);
        LocalStorageManager.Save("admin", _res.isAdmin);

        if (ev.target.save.checked) {
          LocalStorageManager.SaveMultiple([
            { key: "isSaved", value: true },
            { key: "password", value: this.state.password },
            { key: "email", value: this.state.email }
          ]);
        }

        window.location.reload();
      })
      .catch(console.error);

    ev.preventDefault();
  }

  handleRelogin = () => new Promise((res, rej) => {
    if (LocalStorageManager.Get("isSaved")) {
      ClientAPI.Login({
        password: LocalStorageManager.Get("password"),
        email: LocalStorageManager.Get("email")
      })
        .then((_res) => {
          CookieManager.Save("auth", _res.token);
          LocalStorageManager.Save("admin", _res.isAdmin);

          window.location.reload();

          res(_res);
        })
        .catch((_e) => {
          this.handleShow("Ihre alten Daten sind nicht mehr gültig!");
          rej(_e);
        });
    }

    rej("Keine Logindaten gefunden!");
  });

  handleResetPassword = (ev) => {
    ev.preventDefault();

    CookieManager.Delete("auth");
    LocalStorageManager.DeleteMultiple(["admin", "isSaved", "password", "email"]);

    ClientAPI.RequestResetPassword(ev.target.email.value)
      .then((_res) => {
        Events.onResult.emit("success");
      })
      .catch(console.error);
  }

  handleErrorMessage = (errorMessage) => {
    this.setState({ errorMessage: errorMessage });
  }

  componentDidMount() {
    if (window.location.href.includes("register")
      || window.location.href.includes("resetPassword")
      || window.location.href.includes("impressum")
    ) return;

    Events.onResult.on("onAuthTokenInvalid", () => {
      this.handleRelogin()
        .catch((e) => {
          this.handleShow("Ihre Session ist abgelaufen, bitte erneut einloggen um die Daten abzurufen.");
        })
    });

    Events.onResult.on("error", this.handleErrorMessage);

    if (!CookieManager.Get("auth")) {
      this.handleRelogin()
        .catch((e) => {
          this.handleShow("Ihre Session ist abgelaufen, bitte erneut einloggen um die Daten abzurufen.");
        });
    }
  }

  render() {
    return (
      <Modal
        show={this.state.show}
        onHide={this.handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>
          <Modal.Title>{this.state.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={!this.state.resetPassword ? this.handleLogin : this.handleResetPassword}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Label>E-Mail</Form.Label>
              <Form.Control type="email" name="email" placeholder="E-Mail eingeben" onChange={this.handleChange} />
            </Form.Group>

            {
              !this.state.resetPassword ?
                (
                  <div>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Label>Passwort</Form.Label>
                      <Form.Control type="password" name="password" placeholder="Passwort" onChange={this.handleChange} />
                    </Form.Group>

                    <Form.Group className="mb-3" id="formGridCheckbox">
                      <Form.Check name="save" type="checkbox" label="Anmeldedaten speichern" />
                    </Form.Group>

                    <Button variant="primary" type="submit">
                      Einloggen
                    </Button>

                    {
                      this.state.errorMessage ?
                        (<>
                          <p style={{ color: "red", margin: "15px" }}>{this.state.errorMessage}</p>
                        </>)
                        :
                        <></>
                    }
                  </div>
                )
                :
                (
                  <div>
                    <Button variant="primary" type="submit">
                      Passwort zurücksetzen
                    </Button>
                  </div>
                )
            }
          </Form>
        </Modal.Body>
        <Modal.Footer style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
          {
            !this.state.resetPassword ?
              (
                <Button onClick={() => this.setState({ resetPassword: true })} variant="primary" type="submit">
                  Passwort vergessen
                </Button>
              )
              :
              (
                <Button onClick={() => this.setState({ resetPassword: false })} variant="primary" type="submit">
                  Einloggen
                </Button>
              )
          }

          <Button variant="secondary" onClick={this.handleClose}>
            Schließen
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default ReLoginPopup;