import React, { Component } from 'react';
import { Modal, Button } from 'react-bootstrap';

import LocalStorageManager from '../managers/LocalStorageManager';
import PageDescription from '../components/PageDescription';

class AttentionToPricingAndOrdersPopup extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
            title: "",
            email: null,
            password: null,
            resetPassword: false
        }
    }

    handleShow = (title) => this.setState({ show: true, title });

    handleClose = () => {
        LocalStorageManager.Save("pageDescriptionConfirmed", "true");
        this.setState({ show: false });
    }

    componentDidMount() {
        if (LocalStorageManager.Get("pageDescriptionConfirmed") !== "true") {
            this.handleShow("Hinweis zu dieser Webseite und der Maro- Foodcoop");
        }
    }

    render() {
        return (
            <Modal
                show={this.state.show}
                onHide={this.handleClose}
                backdrop="static"
                keyboard={false}
                style={{ zIndex: "10000" }}
            >
                <Modal.Header>
                    <Modal.Title>{this.state.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <PageDescription greetings={true} />
                </Modal.Body>
                <Modal.Footer style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                    <Button variant="secondary" onClick={this.handleClose}>
                        Okay
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default AttentionToPricingAndOrdersPopup;