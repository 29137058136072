import React, { Component } from 'react';
import { Navbar, Container, Nav } from 'react-bootstrap';

class Footer extends Component {
    render() {
        return (
            <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
                <Container>
                    <Nav className="me-auto" style={{ display: "flex", flexDirection: "row", justifyContent: "space-around", width: "100%" }}>
                        <Nav.Link href="/impressum">Impressum und Datenschutz</Nav.Link>
                    </Nav>
                </Container>
            </Navbar>
        )
    }
}

export default Footer;