import React, { Component } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

import AdminAPI from '../service/AdminAPI';

class CreateProductPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fixedValues: true
    };
  }

  handleShow = (title) => this.setState({ show: true, title });

  handleClose = () => {
    this.props.onClose();
  }

  handleSubmit = (ev) => {
    AdminAPI.CreateProduct({
      name: ev.target.name.value,
      price: ev.target.price ? ev.target.price.value : 0,
      weight: ev.target.weight ? ev.target.weight.value : 0,
      isFixed: ev.target.isFixed.checked,
      isPublished: ev.target.isPublished.checked
    })
      .then(() => {
        window.location.reload();
      })
      .catch(console.error);

    ev.preventDefault();
  }

  render() {
    return (
      <Modal
        show={this.props.show}
        onHide={this.handleClose}
      >
        <Modal.Header>
          <Modal.Title>Erstelle ein Produkt</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={this.handleSubmit}>
            <Form.Group className="mb-3" controlId="formProductName">
              <Form.Label>Name des Produkts</Form.Label>
              <Form.Control type="text" name="name" placeholder="Name des Produkts..." />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formWeight">
              <Form.Label>Soll dieses Produkt veröffentlicht werden?</Form.Label>
              <Form.Check name="isPublished"></Form.Check>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formWeight">
              <Form.Label>Hat dieses Produkt ein fixiertes Gewicht und somit einen fixierten Preis?</Form.Label>
              <Form.Check name="isFixed" value={this.state.fixedValues ? 1 : 0} checked={this.state.fixedValues} onChange={() => this.setState({ fixedValues: !this.state.fixedValues })}></Form.Check>
            </Form.Group>

            {
              this.state.fixedValues ?
                <>
                  <Form.Group className="mb-3" controlId="formWeight">
                    <Form.Label>Gewicht</Form.Label>
                    <Form.Control type="number" step="0.01" name="weight" placeholder="Gewicht eingeben..." />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formPrice">
                    <Form.Label>Preis</Form.Label>
                    <Form.Control type="number" step="0.01" name="price" placeholder="Preis eingeben..." />
                  </Form.Group>
                </>
                :
                <></>
            }

            <Button variant="primary" type="submit">
              Hinzufügen
            </Button>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={this.handleClose}>
            Schließen
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default CreateProductPopup;