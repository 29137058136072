import React, { Component } from "react";
import { Form } from "react-bootstrap";

import LocationReader from '../managers/LocationReader';

import ClientAPI from "../service/ClientAPI";
import { Button, Card, CardContent } from "@mui/material";

class ResetPasswordPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            token: LocationReader.ReadParameter("id", props.location.search)
        }
    }

    SetNewPassword = (ev) => {
        ev.preventDefault();

        const _inputs = ev.target,
            _password = _inputs.pwd.value,
            _passwordRepeat = _inputs.pwdrepeat.value,
            _email = _inputs.email.value;

        ClientAPI.SetNewPassword({
            passwordRepeat: _passwordRepeat,
            password: _password,
            email: _email,
            token: this.state.token
        })
            .then((_res) => {
                window.location.replace("/");
            });
    }

    render() {
        return (
            <div style={{ marginTop: "20px", minHeight: "80vh" }}>
                <Card className="tinyForm">
                    <CardContent>
                        <h3>Passwort zurücksetzen</h3>

                        <Form onSubmit={this.SetNewPassword}>

                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>E-Mail</Form.Label>
                                <Form.Control type="email" name="email" placeholder="E-Mail eingeben" onChange={this.handleChange} />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Neues Passwort</Form.Label>
                                <Form.Control type="password" name="pwd" placeholder="..." onChange={this.handleChange} />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicRepeatPassword">
                                <Form.Label>Passwort wiederholen</Form.Label>
                                <Form.Control type="password" name="pwdrepeat" placeholder="..." onChange={this.handleChange} />
                            </Form.Group>

                            <Button type="submit" sx={{ margin: "auto" }} variant="contained" color="success">Bestätigen</Button>
                        </Form>
                    </CardContent>
                </Card>
            </div>
        )
    }
}

export default ResetPasswordPage;