const ReadParameter = (parameter, parameterStr) => {
    try {
        if(parameterStr === "" || !parameter) return;

        const _params = parameterStr.split('?')[1].split('=');

        return _params[_params.findIndex(_param => _param === parameter) + 1];
    }
    catch(e) {
        console.log(e);
        return null;
    }
}

const exportData = { ReadParameter };

export default exportData;