import React, { Component } from "react";

class PageDescription extends Component {
    render() {
        return (
            <div>
                {
                    this.props.greetings === true ?
                        (
                            <>
                                <p><b>Hallo</b>,</p>
                                <p>du bist auf der Seite der Maro- Foodcoop gelandet.</p>
                            </>
                        )
                        :
                        <></>
                }
                <p>Als Bestellgemeinschaft beziehen wir Obst, Gemüse und landwirtschaftliche Produkte bei verschiedenen Bauern und Produzenten in Spanien. Die Bestellgemeinschaft treibt keinen Handel. Die Produzenten erhalten faire Preise und wir bekommen frische und exklusive Waren auf direktem Wege.</p>

                <p>Auf dieser Seite findest du die aktuell verfügbaren Waren <span style={{ textDecoration: "underline" }}>zum Preis der Lieferanten</span>.
                    Du kannst Waren für die nächste Abholung reservieren, indem du diese in deinen Warenkorb legst und bestätigst.
                    Die Natur hat ihre eigenen Regeln und die Transportkapazitäten sind begrenzt. So kann es vorkommen, dass nicht immer Alles verfügbar ist.</p>

                <p><b>Die Bezahlung erfolgt erst bei Abholung.</b> Die Termine zur Abholung werden separat bekannt gegeben.</p>

                <p><b>Dazu können Bestellungen nur getätigt werden, solange der Shop offen ist (Status wird oben auf der Seite angezeigt), die Seite ist zwar aufrufbar und man kann sich einloggen, jedoch sind die Funktionen eingeschränkt.</b></p>

                {
                    this.props.greetings === true ?
                        (
                            <p>Solltest du diese Seite versehentlich aufgerufen haben, wende dich an jemanden, der uns persönlich kennt.</p>
                        )
                        :
                        <></>
                }

                <p>Maro - Food ist eine <b>Bestell Food Coop</b>, dadurch können Produkte variieren.</p>
            </div>
        )
    }
}

export default PageDescription;