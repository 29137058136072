import React, { Component } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'

import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

import AdminAPI from '../service/AdminAPI';
import { TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import Events from '../service/Events';

dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");

dayjs.extend(utc);

class UpdateShopOpenings extends Component {
    constructor(props) {
        super(props);

        this.state = {
            show: false,
            cachedProducts: null,
            products: [],
            startDate: dayjs(new Date()),
            endDate: dayjs(new Date())
        }
    }

    handleShow = (title) => this.setState({ show: true, title });

    handleClose = () => {
        this.setState({ show: false });

        this.props.onClose();
    }

    handleSubmit = (ev) => {
        AdminAPI.UpdateStoreOpeningTimes(dayjs(this.state.startDate).tz("Europe/Paris"), dayjs(this.state.endDate).tz("Europe/Paris"))
            .then(() => window.location.reload())
            .catch(console.error);

        ev.preventDefault();
    }

    componentDidUpdate() {
        if (this.state.show !== this.props.show) {
            this.setState({ show: this.props.show, startDate: this.props.startDate, endDate: this.props.endDate });
        }
    }

    handleChangeStartDate = (newVal) => {
        if(newVal > this.state.endDate) {
            Events.onResult.emit("error", "Anfangsdatum ist größer als Enddatum!");
            return;
        }

        console.log(dayjs(newVal).tz("Europe/Paris"))

        this.setState({ startDate: newVal });
    }

    handleChangeEndDate = (newVal) => {
        if(newVal < this.state.startDate) {
            Events.onResult.emit("error", "Enddatum ist kleiner als Anfangsdatum!");
            return;
        }

        this.setState({ endDate: newVal });
    }

    render() {
        return (
            <Modal
                show={this.state.show}
                onHide={this.handleClose}
            >
                <Modal.Header>
                    <Modal.Title>Bearbeiten der Öffnungszeiten</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={this.handleSubmit} style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                                maxDate={this.state.endDate}
                                label="Start Datum"
                                value={this.state.startDate}
                                onChange={this.handleChangeStartDate}
                                renderInput={(params) => <TextField {...params} />}
                            />

                            <br />

                            <DateTimePicker
                                minDate={this.state.startDate}
                                label="End Datum"
                                value={this.state.endDate}
                                onChange={this.handleChangeEndDate}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>

                        <br />

                        <Button variant="primary" type="submit">
                            Öffnungszeiten aktualisieren
                        </Button>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.handleClose}>
                        Schließen
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default UpdateShopOpenings;