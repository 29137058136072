import {
    EventEmitter
} from 'events';

const onResult = new EventEmitter(),
    onAlert = new EventEmitter();

const exportData = {
    onResult,
    onAlert
};

export default exportData;