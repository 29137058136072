import React, { Component } from "react";
import { Snackbar, Alert } from '@mui/material';

import Events from '../service/Events';

class InfoSnackbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      message: null,
      isError: false,
      isSuccess: false
    }
  }

  componentDidMount() {
    Events.onResult.on("error", (_errorMessage) => {
      this.setState({ isError: true, show: true, message: _errorMessage });
    });

    Events.onResult.on("success", (_successMessage) => {
      this.setState({ isSuccess: true, show: true, message: _successMessage ? _successMessage : "Erfolg!" });
    });
  }

  close = () => {
    this.setState({ show: false, isSuccess: false, isError: false, message: null });
  }

  render() {
    return (
      <div>
        <Snackbar open={this.state.show} autoHideDuration={5000} onClose={this.close}>
          <Alert onClose={this.close} severity={this.state.isError ? "error" : this.state.isSuccess ? "success" : "info"} sx={{ width: '100%' }}>
            {this.state.message}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

export default InfoSnackbar;