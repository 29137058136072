import React, { Component } from "react";

class ErrorPage extends Component {
    render() {
        return (
            <div style={{ minHeight: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
                <h2>Fehler, diese Seite existiert nicht!</h2>
            </div>
        )
    }
}

export default ErrorPage;