import nodeFetch from './fetch';

/**
 * @returns {Promise<Array<{ ID: number, Name: string, Price: number }>>}
 */
const RequestProducts = () => new Promise((resolve, reject) => {
    nodeFetch("products")
        .then(resolve)
        .catch(reject)
});

const GetOrders = () => new Promise((resolve, reject) => {
    nodeFetch("myOrders")
        .then(resolve)
        .catch(reject)
});

const Login = (loginData) => new Promise((resolve, reject) => {
    nodeFetch("login", "post", loginData)
        .then(resolve)
        .catch(reject);
});

const Register = (data) => new Promise((resolve, reject) => {
    nodeFetch("signup", "post", data)
        .then(resolve)
        .catch(reject);
});

const SelectProduct = (productID, amount) => new Promise((resolve, reject) => {
    nodeFetch("selectProduct", "post", {
            productID,
            amount
        })
        .then(resolve)
        .catch(reject);
});

const UpdateMyOrders = (data) => new Promise((resolve, reject) => {
    nodeFetch("updateMyOrders", "post", data)
        .then(resolve)
        .catch(reject);
});

const DeleteOrders = (data) => new Promise((resolve, reject) => {
    nodeFetch("deleteOrders", "post", data)
        .then(resolve)
        .catch(reject);
});

const ConfirmOrder = (orderID) => new Promise((resolve, reject) => {
    nodeFetch("confirmOrder", "post", {
            orderID
        })
        .then(resolve)
        .catch(reject);
});

const ConfirmOrders = (orderIDs) => new Promise((resolve, reject) => {
    nodeFetch("confirmOrders", "post", {
            orders: orderIDs
        })
        .then(resolve)
        .catch(reject);
});

const GetStoreInfo = () => new Promise((resolve, reject) => {
    nodeFetch("getStoreInfo")
        .then(resolve)
        .catch(reject);
});

const GetAddressInfo = () => new Promise((resolve, reject) => {
    nodeFetch("getAddressInfo")
    .then(resolve)
    .catch(reject);
});

/**
 * 
 * @param {{ City, Street, Number, PostalCode, Country }} address 
 * @returns 
 */
const UpdateAddress = (address) => new Promise((resolve, reject) => {
    nodeFetch("updateAddress", "POST", address)
    .then(resolve)
    .catch(reject);
});

/**
 * 
 * @param {{ cpassword, rpassword, password }} passwordData 
 * @returns 
 */
const UpdatePassword = (passwordData) => new Promise((resolve, reject) => {
    nodeFetch("updatePassword", "POST", passwordData)
    .then(resolve)
    .catch(reject);
});

const DeleteAccount = () => new Promise((resolve, reject) => {
    nodeFetch("deleteAccount", "POST", {})
    .then(resolve)
    .catch(reject);
});

/**
 * 
 * @param {{ token, password, passwordRepeat, email }} passwordData 
 * @returns 
 */
const SetNewPassword = (passwordData) => new Promise((resolve, reject) => {
    nodeFetch("setNewPassword", "POST", passwordData)
    .then(resolve)
    .catch(reject);
});

const RequestResetPassword = (email) => new Promise((resolve, reject) => {
    nodeFetch("requestResetPassword", "POST", { email })
    .then(resolve)
    .catch(reject);
});

const exportData = {
    RequestProducts: RequestProducts,
    Login: Login,
    GetOrders: GetOrders,
    Register,
    SelectProduct,
    UpdateMyOrders,
    DeleteOrders,
    ConfirmOrder,
    GetStoreInfo,
    ConfirmOrders,
    GetAddressInfo,
    UpdateAddress,
    UpdatePassword,
    DeleteAccount,
    SetNewPassword,
    RequestResetPassword
};

export default exportData;