import React, { Component } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';
import { TextField } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import Events from '../service/Events';

import AdminAPI from '../service/AdminAPI';

class CreateUserPopup extends Component {
    constructor(props) {
      super(props);

      this.state = {
        toggleValue: "single",
        show: false,
        title: "",
        startDate: dayjs(new Date()),
        endDate: dayjs(new Date()),
        isInifiniteEntries: false
      }
    }

    handleClose = () => {
      this.setState({ isInifiniteEntries: false, toggleValue: "single" });

      this.props.onClose();
    }

    handleSubmit = (ev) => {
      AdminAPI.CreateUser({
        email: ev.target.email.value
      })
      .then((_res) => {
        window.location.reload();
      })
      .catch(console.error);

      ev.preventDefault();
    }

    handleSubmitForMultiple = (ev) => {
      AdminAPI.CreateInvite({
        isInfinite: this.state.isInifiniteEntries,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
        amount: ev.target.amount ? parseInt(ev.target.amount.value) : 0
      })
      .then((_res) => {
        var _websitePort = process.env.REACT_APP_WEBSITE_PORT;

        navigator.clipboard.writeText(`${process.env.REACT_APP_DOMAIN}${(_websitePort ? `:${_websitePort}` : "")}/register?${_res.token}`)
        .then(() => {
          Events.onResult.emit('success', "Der Link wurde kopiert");
        
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch(() => window.location.reload());
      })
      .catch(console.error);

      ev.preventDefault();
    }

    handleChange = (ev, value) => {
      this.setState({ toggleValue: value })
    }

    handleChangeStartDate = (newVal) => {
      if(newVal > this.state.endDate) {
          Events.onResult.emit("error", "Anfangsdatum ist größer als Enddatum!");
          return;
      }

      this.setState({ startDate: newVal });
    }

    handleChangeEndDate = (newVal) => {
        if(newVal < this.state.startDate) {
            Events.onResult.emit("error", "Enddatum ist kleiner als Anfangsdatum!");
            return;
        }

        this.setState({ endDate: newVal });
    }

    handleHasInfiniteEntriesChange = (ev) => {
      this.setState({ isInifiniteEntries: !this.state.isInifiniteEntries });
    }

    render() {
      return (
          <Modal
            show={this.props.show}
            onHide={this.handleClose}
          >
            <Modal.Header>
              <Modal.Title>Nutzer hinzufügen</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
              <ToggleButtonGroup
                style={{ margin: "auto" }}
                color="primary"
                value={this.state.toggleValue}
                exclusive
                onChange={this.handleChange}
                aria-label="Platform"
              >      
                <ToggleButton value="single">Einzelner Nutzer</ToggleButton>
                <ToggleButton value="multiple">Mehrere Nutzer</ToggleButton>
              </ToggleButtonGroup>
              <br />
              {
                this.state.toggleValue === "single" ? 
                (<Form onSubmit={this.handleSubmit}>
                  <Form.Group className="mb-3" controlId="formProductName">
                    <Form.Label>Email des Kunden</Form.Label>
                    <Form.Control type="email" name="email" placeholder="kunde@gmail.com..." />
                  </Form.Group>

                  <Button variant="primary" type="submit">
                    Hinzufügen 
                  </Button>
                </Form>)
                :
                (<Form onSubmit={this.handleSubmitForMultiple}>
                  <Form.Group className="mb-3" controlId="formProductName" 
                    style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "space-around" }} >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <MobileDateTimePicker
                        className='datePickerInPopup'
                        label="Start Datum"
                        value={this.state.startDate}
                        onChange={this.handleChangeStartDate}
                        renderInput={(params) => <TextField {...params} />}
                      />

                      <MobileDateTimePicker
                        className='datePickerInPopup'
                        label="End Datum"
                        value={this.state.endDate}
                        onChange={this.handleChangeEndDate}
                        renderInput={(params) => <TextField {...params} />}
                      />
                    </LocalizationProvider>

                    <Form.Label style={{ alignSelf: "flex-start" }} >Besitzt kein Nutzlimit (Unendliche Nutzung)</Form.Label>
                    <Form.Check style={{ alignSelf: "flex-start" }} name="isInfinite" value={this.state.isInifiniteEntries} onChange={this.handleHasInfiniteEntriesChange} />

                    {
                      !this.state.isInifiniteEntries ?
                      (<>
                        <Form.Label style={{ alignSelf: "flex-start" }} >Maxmimale Anzahl von Anmeldungen</Form.Label>
                        <Form.Control type="number" name="amount" placeholder="10"  />
                      </>)
                      : <></>
                    } 
                  </Form.Group>

                  <Button variant="primary" type="submit">
                    Hinzufügen 
                  </Button>
                </Form>)
              }
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleClose}>
                Schließen
              </Button>
            </Modal.Footer>
          </Modal>
      );
    }
}

export default CreateUserPopup;