import React, { Component } from "react";
import { Form, Row, Col } from 'react-bootstrap';

import { Button, Card, CardContent } from '@mui/material';

import CookieManager from "../managers/CookieManager";
import LocalStorageManager from "../managers/LocalStorageManager";
import Events from '../service/Events';

import ClientAPI from "../service/ClientAPI";

class Account extends Component {

    onUpdatePassword = (ev) => {
        const _inputs = ev.target,
            _password = _inputs.password.value,
            _rpassword = _inputs.rpassword.value,
            _cpassword = _inputs.cpassword.value;

        if (!_cpassword || !_password || !_rpassword || _password !== _rpassword) {
            Events.onResult.emit("error", "Passwörter stimmen nicht überein");

            ev.preventDefault();
            return;
        }

        ClientAPI.UpdatePassword({ cpassword: _cpassword, rpassword: _rpassword, password: _password })
            .then((_res) => {
                CookieManager.Save("auth", _res.token);
                LocalStorageManager.Save("admin", _res.isAdmin);

                if (LocalStorageManager.Get("isSaved")) {
                    LocalStorageManager.SaveMultiple([
                        { key: "password", value: _password },
                        { key: "email", value: _res.email }
                    ]);
                }

                Events.onResult.emit("success", "Passwort wurde aktualisiert!");
            })

        ev.preventDefault();
    }

    deleteAccount = () => {
        Events.onAlert.emit("warning", "Account Löschung", "Wenn Sie Ihren Account löschen werden alle Ihre Bestellungen und Login - Daten gelöscht, sind Sie sich sicher?"
            , () => {
                ClientAPI.DeleteAccount()
                    .then(() => {
                        CookieManager.Delete("auth");
                        LocalStorageManager.DeleteMultiple(["admin", "password", "email", "isSaved"]);

                        window.location.replace("/");
                    })
            });
    }

    render() {
        return (
            <div style={{ minHeight: "90vh", margin: "auto", marginTop: "25px" }}>

                <Card className="tinyForm">
                    <CardContent>
                        <Form onSubmit={this.onUpdatePassword}>
                            <Form.Group className="mb-3" as={Col} controlId="formGridCurrentPassword">
                                <Form.Label>Derzeitiges Passwort</Form.Label>
                                <Form.Control name="cpassword" type="password" placeholder="Passwort" />
                            </Form.Group>

                            <Form.Group className="mb-3" as={Col} controlId="formGridPassword">
                                <Form.Label>Neues Passwort</Form.Label>
                                <Form.Control name="password" type="password" placeholder="Passwort" />
                            </Form.Group>

                            <Form.Group className="mb-3" as={Col} controlId="formGridRepeatPassword">
                                <Form.Label>Passwort wiederholen</Form.Label>
                                <Form.Control name="rpassword" type="password" placeholder="Passwort wiederholen" />
                            </Form.Group>

                            <Button type="submit" variant="contained">Aktualisieren</Button>
                        </Form>
                    </CardContent>
                </Card>

                <div style={{ margin: "auto", marginTop: "20px", width: "95%", display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                    <Button onClick={this.deleteAccount} variant="contained" color="error">Account löschen</Button>
                </div>
            </div>);
    }
}

export default Account;