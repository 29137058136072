import React, { Component } from 'react';
import { Modal, Button, Form, Row } from 'react-bootstrap';
import AdminAPI from '../service/AdminAPI';

class SetCustomPriceWeightPopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      cachedOrder: null,
      order: null
    }
  }

  handleClose = () => {
    this.setState({ show: false });

    this.props.onClose();
  }

  handleSubmit = (ev) => {
    var _order = this.state.cachedOrder;

    AdminAPI.SetPriceWeightForCustomerOrder(_order.orderID, _order.CustomPrice, _order.CustomWeight)
      .then((_res) => {
        window.location.reload();
      })
      .catch(console.error);

    this.props.onClose();
    ev.preventDefault();
  }

  componentDidUpdate() {
    if (this.state.show !== this.props.show) {
      this.setState({ show: this.props.show, order: this.props.order, cachedOrder: Object.assign({}, this.props.order) });
    }
  }

  handlePriceChange = (ev) => {
    this.state.cachedOrder.CustomPrice = parseFloat(ev.target.value);

    this.setState({ cachedOrder: this.state.cachedOrder });
  }

  handleWeightChange = (ev) => {
    this.state.cachedOrder.CustomWeight = parseFloat(ev.target.value);

    this.setState({ cachedOrder: this.state.cachedOrder });
  }

  resetEntry = () => {
    this.state.cachedOrder.CustomPrice = this.state.order.CustomPrice;
    this.state.cachedOrder.CustomWeight = this.state.order.CustomWeight;

    this.setState({ cachedOrder: this.state.cachedOrder });
  }

  render() {
    return (
      this.state.cachedOrder ?
        (
          <Modal
            show={this.state.show}
            onHide={this.handleClose}
          >
            <Modal.Header>
              <Modal.Title>Preis für Einzelprodukt bearbeiten</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={this.handleSubmit}>
                <div key={this.state.order.orderID}>
                  <Form.Group className="mb-3" controlId="formProductName">
                    <Form.Label>Produktname</Form.Label>
                    <Form.Control type="text" name={this.state.order.Name} value={this.state.cachedOrder.Name} readOnly />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formPrice">
                    <Form.Label>Preis (€)</Form.Label>
                    <Form.Control type="number" step={0.01} min="0" name="Price" value={this.state.cachedOrder.CustomPrice} onChange={this.handlePriceChange} />
                  </Form.Group>

                  <Form.Group className="mb-3" controlId="formWeight">
                    <Form.Label>Gewicht (Kg)</Form.Label>
                    <Form.Control type="number" step={0.01} min="0" name="Weight" value={this.state.cachedOrder.CustomWeight} onChange={this.handleWeightChange} />
                  </Form.Group>

                  <Row>
                    <Button style={{ maxWidth: "75%", margin: "auto", marginBottom: "10px" }} onClick={() => this.resetEntry()} >
                      Zurücksetzen
                    </Button>
                  </Row>
                  <br />
                  <hr />
                </div>
                <Button variant="primary" type="submit">
                  Preis und Gewicht aktualisieren
                </Button>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleClose}>
                Schließen
              </Button>
            </Modal.Footer>
          </Modal>
        )
        :
        <></>
    );
  }
}

export default SetCustomPriceWeightPopup;