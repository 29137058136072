import { CardActions, CardContent, Typography, Button } from "@mui/material";
import React, { Component } from "react";
import { Card } from "react-bootstrap";

class ProductItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            product: props.product
        }
    }

    componentDidUpdate() {
        if (this.state.product !== this.props.product) {
            this.setState({ product: this.props.product });
        }
    }

    selectProduct() {
        this.props.selectedProduct(this.state.product);
    }

    render() {
        return (
            <Card style={{ width: "350px", minHeight: "200px", display: "flex", flexDirection: "column", justifyContent: "space-between" }} variant="outlined">
                <CardContent>
                    <Typography variant="h6" component="div">
                        {this.state.product.Name}
                    </Typography>
                    <Typography variant="h8" component="div">
                        {`Preis: ${!this.state.product.isFixed ? "Wird nach Bestätigung berechnet" : this.state.product.Price + "€"}`}
                    </Typography>
                    <Typography variant="h8" component="div">
                        {`Gewicht: ${!this.state.product.isFixed ? "Wird nach Bestätigung berechnet" : this.state.product.Weight + "Kg/L"}`}
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button variant="outlined" size="small" onClick={() => this.selectProduct()}>Produkt hinzufügen</Button>
                </CardActions>
            </Card>
        )
    }
}

export default ProductItem;